import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Layout from "../components/Global/layout";
import SEO from "../components/seo/SEO";

const PrivacyPolicy = () => {
  const data = useStaticQuery(graphql`
    {
      prismicPrivacyPolicy {
        data {
          title {
            text
          }
          privacy_policy {
            html
          }
        }
      }
    }
  `);
  return (
    <>
<SEO />
      <Layout>
        <section className="contact-options-grid">
          <div className="p-2 bg-gray-900"></div>
          <div className="flex flex-col space-y-3 md:space-y-4 bg-gray-900 p-8 md:p-12">
            <div
              className="prose prose-blue w-full mx-auto"
              dangerouslySetInnerHTML={{
                __html: data.prismicPrivacyPolicy.data.privacy_policy.html,
              }}
            ></div>
          </div>
          <div className="p-2 bg-gray-900"></div>
        </section>
      </Layout>
    </>
  );
};

export default PrivacyPolicy;
